import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { version } from "../../network/api/config";

const NavMenu = ({ menuWhiteClass, sidebarMenu }) => {
  const { t } = useTranslation();
  const userId = localStorage.getItem("idUser");
  const handleClick = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className={clsx(
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      )}
    >
      <nav>
        <ul>
          <li>
            <Link to={"/"}>{t("Home")}</Link>
          </li>

          <li>
            <Link to={"/categories"}>{t("Shop")}</Link>
          </li>
          <li>
            <Link to="#" onClick={handleClick}>
              {t("My Profile")}
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="submenu">
              <li>
                {userId ? (
                  <Link to={"/cart"}>{t("cart")}</Link>
                ) : (
                  <Link to={"/login-register"}>{t("cart")}</Link>
                )}
              </li>

              <li>
                {userId ? (
                  <Link to={"/wishlist"}>{t("wishlist")}</Link>
                ) : (
                  <Link to={"/login-register"}>{t("wishlist")}</Link>
                )}
              </li>
              <li>
                {userId ? (
                  <Link to={"/order"}>{t("Orders")}</Link>
                ) : (
                  <Link to={"/login-register"}>{t("Orders")}</Link>
                )}
              </li>
              <li>
                {userId ? (
                  <Link to={"/my-account"}>{t("My Account")}</Link>
                ) : (
                  <Link to={"/login-register"}>{t("My Account")}</Link>
                )}
              </li>
              <li>
                <Link to={"/contact"}>{t("contact us")}</Link>
              </li>

              <li style={{ textAlign: "left", marginTop: "5px" }}>
                Version : {version}
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavMenu;
