import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import clsx from "clsx";
import dataService from "../../network/api/services/dataService";

const IconGroup = ({
  iconWhiteClass,
  updateCartValue,
  updateWishList,
  updateCartValueShop,
  updateCartSize,
}) => {
  const [cartData, setCartData] = useState([]);
  const [wishlistData, setWishlistData] = useState([]);
  const [isAccountDropdownVisible, setIsAccountDropdownVisible] =
    useState(false);
  const accountDropdownRef = useRef(null);
  const userId = localStorage.getItem("idUser");

  useEffect(() => {
    const fetchCartData = async () => {
      const id = localStorage.getItem("idUser");

      if (!id) return;
      try {
        const response = await dataService.getCart(id);
        setCartData(response.cart.products);
      } catch (error) {
        console.error("Error fetching cart data:", error);
      }
    };
    fetchCartData();
  }, [updateCartValue, updateCartValueShop, updateCartSize]);

  useEffect(() => {
    const fetchWishlistData = async () => {
      const id = localStorage.getItem("idUser");

      if (!id) return;
      try {
        const response = await dataService.getWishlist();
        setWishlistData(response.products);
      } catch (error) {
        console.error("Error fetching wishlist data:", error);
      }
    };
    fetchWishlistData();
  }, [updateWishList]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accountDropdownRef.current &&
        !accountDropdownRef.current.contains(event.target)
      ) {
        setIsAccountDropdownVisible(false);
      }
    };

    if (isAccountDropdownVisible) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isAccountDropdownVisible]);

  const handleClick = (e) => {
    setIsAccountDropdownVisible((prev) => !prev);
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };

  const renderAccountLinks = () => {
    if (!userId) {
      return (
        <>
          <li>
            <Link to="/login-register">Login</Link>
          </li>
          <li>
            <Link to="/login-register">Register</Link>
          </li>
        </>
      );
    }
    return (
      <>
        <li>
          <Link to="/my-account">My Account</Link>
        </li>
        <li>
          <Link
            to="/login-register"
            onClick={() => {
              localStorage.removeItem("idUser");
              localStorage.removeItem("jwtTokenUser");
            }}
          >
            Logout
          </Link>
        </li>
      </>
    );
  };

  const renderIconWithCount = (iconClass, count) => (
    <span className="count-style">{count}</span>
  );

  return (
    <div className={clsx("header-right-wrap", iconWhiteClass)}>
      <div className="same-style header-search d-none d-lg-block">
        <div className="search-content">
          <form action="#">
            <input type="text" placeholder="Search" />
            <button className="button-search">
              <i className="pe-7s-search" />
            </button>
          </form>
        </div>
      </div>
      <div
        className="same-style account-setting d-none d-lg-block"
        ref={accountDropdownRef}
      >
        <button className="account-setting-active" onClick={handleClick}>
          <i className="pe-7s-user-female" />
        </button>
        <div
          className={clsx("account-dropdown", {
            active: isAccountDropdownVisible,
          })}
        >
          <ul>{renderAccountLinks()}</ul>
        </div>
      </div>
      <div className="same-style header-wishlist">
        <Link to={userId ? "/wishlist" : "/login-register"}>
          <i className="pe-7s-like" />
          {userId &&
            wishlistData.length > 0 &&
            renderIconWithCount("pe-7s-like", wishlistData.length)}
        </Link>
      </div>
      <div className="same-style cart-wrap d-none d-lg-block">
        <Link className="icon-cart" to={userId ? "/cart" : "/login-register"}>
          <button className="icon-cart">
            <i className="pe-7s-shopbag" />
            {userId &&
              cartData.length > 0 &&
              renderIconWithCount("pe-7s-shopbag", cartData.length)}
          </button>
        </Link>
      </div>
      <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to="/cart">
          <i className="pe-7s-shopbag" />
          {cartData.length > 0 &&
            renderIconWithCount("pe-7s-shopbag", cartData.length)}
        </Link>
      </div>
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button className="mobile-aside-button" onClick={triggerMobileMenu}>
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  iconWhiteClass: PropTypes.string,
  updateCartValue: PropTypes.bool,
  updateWishList: PropTypes.bool,
  updateCartValueShop: PropTypes.bool,
};

export default IconGroup;
