import PropTypes from "prop-types";
import { useState } from "react"; // Import useState hook
import { setActiveSort, setShopVendors } from "../../helpers/product";
import dataService from "../../network/api/services/dataService";

const ShopVendors = ({
  vendors,
  getSortParams,
  setProducts,
  setSelectedVendors,
  selectedVendors,
  handleVendorSelect,
}) => {
  return (
    <div
      className="sidebar-widget"
      style={{ height: "200px", overflowY: "scroll",minWidth:"200px" }}
    >
      <h4 className="pro-sidebar-title">Vendors</h4>
      <div className="sidebar-widget-list mt-30">
        {vendors ? (
          <ul>
            <li>
              <div className="sidebar-widget-list-left-vendor">
                <button
                  onClick={(e) => {
                    getSortParams("vendors", "");
                    setSelectedVendors([]);
                    setShopVendors(e);
                  }}
                >
                  <span className="checkmark" /> All Vendors
                </button>
              </div>
            </li>
            {vendors.map((vendor, key) => {
              const isSelected = selectedVendors.includes(vendor._id);
              return (
                <li key={key}>
                  <div className="sidebar-widget-list-left-vendor">
                    <button
                      onClick={(e) => {
                        handleVendorSelect(vendor._id);
                        setShopVendors(e);
                      }}
                    >
                      {" "}
                      <span className="checkmark" />  {vendor.firstName} {" "}
                     
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          "No vendors found"
        )}
      </div>
    </div>
  );
};

ShopVendors.propTypes = {
  vendors: PropTypes.array,
  getSortParams: PropTypes.func,
};

export default ShopVendors;
