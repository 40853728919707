export const LOGIN = "users/sign-in"
export const REGISTER = "users/sign-up"
export const FORGOT_PASSWORD = "users/forgot-password"
export const WISHLIST_PRODUCT = "users/get-wishlist-products"
export const PRODUCT_LIST = "products/get-products";
export const GET_PRODUCT_LIST = "products/get-new-products";

export const GET_BANNERS = "users/get-banners"
export const PRODUCT_INFO = "products/get-product-details"
export const GET_CART="users/get-cart"
export const ADD_CART="users/update-cart/"
export const GET_CATEGORIES = "users/get-categories";
export const ADD_WISHLIST = "users/wishlist-product"
export const PROFILE_DATA = "users/get-user-detail"
export const DELETE_ADDRESS = "users/delete-address"
export const ADD_ADDRESS ="users/add-address"
export const EDIT_ADDRESS ="users/edit-address"
export const EDIT_USER_PROFILE ="users/edit-user"

export const GET_ORDERS ="users/get-orders"
export const PLACE_ORDER ="users/place-order"
export const COUPON = "users/validate-coupon"
export const RAZORPAY_ORDER ="users/create-razorpay-order"
export const GENERATE_INVOICE ="users/send-invoice"


export const GET_NEARBY_VENDORS = "users/get-vendors"
export const GET_VENDOR_PRODUCTS = "users/get-vendor-products"
export const UPLAOD_PROFILE = "vendors/upload-product-image"



