// import { Fragment, useEffect, useState } from "react";
// import PropTypes from "prop-types";
// import clsx from "clsx";
// import { Link } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import Rating from "./sub-components/ProductRating";
// import { getDiscountPrice } from "../../helpers/product";
// import ProductModal from "./ProductModal";
// // import { addToCart } from "../../store/slices/cart-slice";
// import { addToWishlist } from "../../store/slices/wishlist-slice";
// import dataService from "../../network/api/services/dataService";
// import { useNavigate } from "react-router-dom";
// import { Heart, HeartFill } from "react-bootstrap-icons";
// import { message } from "antd";

// const ProductGridSingle = ({
//   product,
//   currency,
//   cartItem,
//   wishlistItem,
//   compareItem,
//   spaceBottomClass,
//   refresh,
//   updateWishlistFromTab,
//   updateCart,
// }) => {
//   const navigate = useNavigate();

//   const [modalShow, setModalShow] = useState(false);
//   // const discountedPrice = getDiscountPrice(product?.price, product?.discount);
//   // const finalProductPrice = +(product?.price * currency.currencyRate).toFixed(2);
//   const discountedPrice = product?.price - product?.sellingPrice;
//   const finalProductPrice = product?.sellingPrice;
//   const discountPercentage = Math.floor(
//     (Math.abs(product?.price - product?.sellingPrice) / product?.price) * 100
//   );

//   // const finalDiscountedPrice = +(
//   //   discountedPrice * currency.currencyRate
//   // ).toFixed(2);
//   const finalDiscountedPrice = product?.price;
//   const dispatch = useDispatch();
//   // const currencySymbol = "₹";
//   const currencySymbol = useSelector(
//     (state) => state.CurrencySymbol.currencySymbol
//   );
//   // console.log(product,"product data")

//   const addToCart = async (product) => {
//     console.log("addToCart", product);
//     const userId = localStorage.getItem("idUser");
//     const data = {
//       productId: product?._id,
//       quantity: "1",
//     };
//     try {
//       const response = await dataService.addCart(data, userId);
//       console.log(response, "response addcart Data");
//       // setcartData(response.cart.products);
//       // updateCart()
//       navigate("/cart");
//     } catch (error) {
//       console.error("Error fetching cart data:", error);
//     }
//   };

//   const isWishlisted = product?.isWishlist;

//   const handleToggleWishlist = async () => {
//     console.log("Toggle wishlist");
//     const payload = {
//       productId: product?._id,
//     };

//     try {
//       const response = await dataService.addToWishlist(payload);
//       console.log(response);
//       message.success(response.message);
//       // refresh();
//       updateWishlistFromTab();
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <Fragment>
//       <div className={clsx("product-wrap", spaceBottomClass)}>
//         <div className="product-img">
//           <Link
//             to={`/product/${product?._id}`}
//             state={{ productDatas: product }}
//           >
//             <img className="default-img" src={product?.images[0]} alt="" />
//             {/* {product?.image?.length > 1 ? ( */}
//             <img className="hover-img" src={product?.images[0]} alt="" />
//             {/* ) : (
//               ""
//             )} */}
//           </Link>
//           {discountPercentage || product?.new ? (
//             <div className="product-img-badges">
//               {discountPercentage ? (
//                 <span className="pink">-{discountPercentage}%</span>
//               ) : (
//                 ""
//               )}
//               {product?.new ? <span className="purple">New</span> : ""}
//             </div>
//           ) : (
//             ""
//           )}

//           <div className="product-action">
//             <div className="pro-same-action pro-wishlist">
//               {isWishlisted === true ? (
//                 <HeartFill
//                   className="heart-icon text-danger"
//                   size={15}
//                   onClick={handleToggleWishlist}
//                 />
//               ) : (
//                 <Heart
//                   className="heart-icon"
//                   onClick={handleToggleWishlist}
//                   size={15}
//                 />
//               )}
//             </div>
//             <div className="pro-same-action pro-cart">
//               {product?.affiliateLink ? (
//                 {
//                   /* <a
//                   href={product?.affiliateLink}
//                   rel="noopener noreferrer"
//                   target="_blank"
//                 >
//                   Buy now
//                 </a> */
//                 }
//               ) : product?.variation && product?.variation?.length >= 1 ? (
//                 {
//                   /* <Link to={`/product/${product?._id}`} state={{ productDatas: product }}>
//                   Select Option
//                 </Link> */
//                 }
//               ) : product?.quantity && product?.quantity > 0 ? (
//                 <button
//                   // onClick={() => dispatch(addToCart(product))}
//                   onClick={() => addToCart(product)}
//                   className={
//                     product !== undefined && product?.quantity > 0
//                       ? "active"
//                       : ""
//                   }
//                   // disabled={product !== undefined && product?.quantity > 0}
//                   // title={
//                   //   product !== undefined  || product?.quantity > 0 ? "Added to cart" : "Add to cart"
//                   // }
//                 >
//                   {" "}
//                   <i className="pe-7s-cart"></i>{" "}
//                   {product !== undefined &&
//                   product?.quantity > 0 &&
//                   product?.isAddedToCart
//                     ? "Added"
//                     : "Add to cart"}
//                 </button>
//               ) : (
//                 <button disabled className="active">
//                   Out of Stock
//                 </button>
//               )}
//             </div>
//             <div className="pro-same-action pro-quickview">
//               <button title="Quick View" onClick={() => setModalShow(true)}>
//                 <i className="pe-7s-look" />
//               </button>
//             </div>
//           </div>
//         </div>
//         <div className="product-content text-center">
//           <h3>
//             <Link
//               to={`/product/${product?._id}`}
//               state={{ productDatas: product }}
//             >
//               {product?.name}
//             </Link>
//           </h3>
//           {product?.ratings && product?.ratings > 0 ? (
//             <div className="product-rating">
//               <Rating ratingValue={product?.ratings} />
//             </div>
//           ) : (
//             ""
//           )}
//           <div className="product-price">
//             {discountedPrice !== null ? (
//               <Fragment>
//                 <span>{currencySymbol + product?.sellingPrice}</span>{" "}
//                 <span className="old">{currencySymbol + product?.price}</span>
//               </Fragment>
//             ) : (
//               <span>{currencySymbol + finalProductPrice} </span>
//             )}
//           </div>
//         </div>
//       </div>
//       {/* product modal */}
//       <ProductModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//         product={product}
//         currency={currency}
//         discountedPrice={discountedPrice}
//         finalProductPrice={finalProductPrice}
//         finalDiscountedPrice={finalDiscountedPrice}
//         wishlistItem={isWishlisted}
//         refresh={refresh}
//         compareItem={compareItem}
//       />
//     </Fragment>
//   );
// };

// ProductGridSingle.propTypes = {
//   cartItem: PropTypes.shape({}),
//   compareItem: PropTypes.shape({}),
//   wishlistItem: PropTypes.shape({}),
//   currency: PropTypes.shape({}),
//   product: PropTypes.shape({}),
//   sliderClassName: PropTypes.string,
//   spaceBottomClass: PropTypes.string,
// };

// export default ProductGridSingle;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip, message } from "antd";
import {
  HeartOutlined,
  HeartFilled,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import dataService from "../../network/api/services/dataService";
import "./ProductGridSingle.css"; // Assuming we have some basic CSS here

const ProductGridSingle = ({
  product,
  refresh,
  updateWishlistFromTab,
  updateCart,
}) => {
  const navigate = useNavigate();
  const [isWishlisted, setIsWishlisted] = useState(product?.isWishlist);
  const currencySymbol = "₹";

  const addToCart = async (product) => {
    console.log("addToCart", product);
    const userId = localStorage.getItem("idUser");
    const data = {
      productId: product?._id,
      quantity: "1",
    };
    try {
      const response = await dataService.addCart(data, userId);
      console.log(response, "response addcart Data");
      message.success("Product added to cart successfully");
      updateCart();

      // setcartData(response.cart.products);
      // updateCart()
      // navigate("/cart");
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  // const addToCart = async () => {
  //   const userId = localStorage.getItem("idUser");
  //   const data = {
  //     productId: product._id,
  //     quantity: product.initialQuantity,
  //   };
  //   try {
  //     await dataService.addCart(data, userId);
  //     message.success("Product added to cart successfully");
  //     updateCart();
  //   } catch (error) {
  //     console.error("Error adding to cart:", error);
  //     message.error("Failed to add product to cart");
  //   }
  // };

  const toggleWishlist = async () => {
    const payload = { productId: product._id };
    try {
      const response = await dataService.addToWishlist(payload);
      message.success(response.message);
      setIsWishlisted(!isWishlisted); // Toggle wishlist state
      updateWishlistFromTab();
    } catch (error) {
      console.error("Error toggling wishlist:", error);
      message.error("Failed to update wishlist");
    }
  };

  return (
    <div className="pgs-card">
      <Link to={`/product/${product?._id}`} state={{ productDatas: product }}>
        <img
          src={
            product.images?.length > 0
              ? product.images[0]
              : "https://via.placeholder.com/150"
          }
          alt={product?.name || "Product Image"}
          className="pgs-img"
        />
      </Link>

      <div className="pgs-details">
        <h3 className="pgs-title">
          <Link
            to={`/product/${product?._id}`}
            state={{ productDatas: product }}
          >
            {product?.name}
          </Link>
        </h3>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <p className="pgs-price">
            {currencySymbol}
            {product?.sellingPrice}{" "}
            {product?.price > product?.sellingPrice && (
              <span className="pgs-original-price">
                {currencySymbol}
                {product?.price}
              </span>
            )}
          </p>

          <Tooltip title="Add to Cart">
            <ShoppingCartOutlined
              className="pgs-icon pgs-cart"
              onClick={() => {
                addToCart(product);
              }}
              // disabled={!product?.allowOrders || product?.quantity === 0}
            />
          </Tooltip>
        </div>
      </div>

      {/* <div className="pgs-actions">
          <Tooltip title="Add to Wishlist">
            {isWishlisted ? (
              <HeartFilled
                className="pgs-icon pgs-wishlist"
                onClick={toggleWishlist}
              />
            ) : (
              <HeartOutlined className="pgs-icon" onClick={toggleWishlist} />
            )}
          </Tooltip>
        </div> */}
    </div>
  );
};

ProductGridSingle.propTypes = {
  product: PropTypes.object.isRequired,
  refresh: PropTypes.func,
  updateWishlistFromTab: PropTypes.func,
  updateCart: PropTypes.func,
};

export default ProductGridSingle;
