import { base_url } from '../config';
import { ADD_ADDRESS, ADD_CART, ADD_WISHLIST, DELETE_ADDRESS, EDIT_ADDRESS, GET_BANNERS, GET_CART, GET_CATEGORIES, GET_ORDERS, PLACE_ORDER, PRODUCT_INFO, PRODUCT_LIST, PROFILE_DATA, RAZORPAY_ORDER, WISHLIST_PRODUCT,GET_VENDOR_PRODUCTS,GET_NEARBY_VENDORS, COUPON, GET_PRODUCT_LIST, GENERATE_INVOICE, EDIT_USER_PROFILE, UPLAOD_PROFILE } from '../endpoint';
import { apiDelete, apiGet, apiPost, apiPut } from '../helpers/apiHelpers';






const getProducts = async (categoryId,sort,limit,skip) => {
  //     if(limit === undefined ){
// limit = ""
//     }
//     if( skip === undefined){
//       skip = ""
//           }
  console.log("getProductList",)
    try {
      const response = await apiGet(`${base_url}${PRODUCT_LIST}?categoryId=${categoryId}&sortBy=${sort}&limit=${limit}&skip=${skip}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getProductList = async (lat,long) => {
      try {
        const response = await apiGet(`${base_url}${GET_PRODUCT_LIST}?latitude=${long}&longitude=${lat}`);
        return response;
      } catch (error) {
        throw error;
      }
    };

  

  const getBanners = async () => {
    try {
      const response = await apiGet(`${base_url}${GET_BANNERS}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getWishlist = async (userData) => {
    try {
      const response = await apiGet(`${base_url}${WISHLIST_PRODUCT}`, userData);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const uploadProfilePhoto = async (file) => {
    try {
      const response = await apiPost(`${base_url}${UPLAOD_PROFILE}`, file);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getProductInfo = async (id) => {
    console.log("getProductInfo", id);
    try {
      const response = await apiGet(`${base_url}${PRODUCT_INFO}/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  };


  const getCategories = async () => {
    try {
      const response = await apiGet(`${base_url}${GET_CATEGORIES}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const addToWishlist = async (userData) => {
    try {
      const response = await apiPost(`${base_url}${ADD_WISHLIST}`, userData);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const getCart = async (id) => {
    try {
      const response = await apiGet(`${base_url}${GET_CART}?&userId=${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const addCart = async (product,userId) => {
    try {
      const response = await apiPut(`${base_url}${ADD_CART}${userId}`,product);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const getAccount = async () => {
    try {
      const response = await apiGet(`${base_url}${PROFILE_DATA}`);
      return response;
    } catch (error) {
      throw error;
    }
  }


  const editProfile = async (data) => {
    try {
      const response = await apiPut(`${base_url}${EDIT_USER_PROFILE}`,data);
      return response;
    } catch (error) {
      throw error;
    }
  }





  const addAddress = async (userData) => {
    try {
      const response = await apiPost(`${base_url}${ADD_ADDRESS}`, userData);
      return response;
    }
    catch (error) {
      throw error;
    }
  }

  const updateAddress = async (id, userData) => {
    try {
      const response = await apiPut(`${base_url}${EDIT_ADDRESS}/${id}` , userData);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const deleteAddress = async (addressId) => {
    try {
      const response = await apiDelete(`${base_url}${DELETE_ADDRESS}/${addressId}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const getOrders = async () => {
    try {
      const response = await apiGet(`${base_url}${GET_ORDERS}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const placeOrder = async (data) => {
    try {
      const response = await apiPost(`${base_url}${PLACE_ORDER}`,data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const razorpayOrder = async () => {
    try {
      const response = await apiPost(`${base_url}${RAZORPAY_ORDER}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const getNearbyVendors = async (lat,long) => {
    try {
      const response = await apiGet(`${base_url}${GET_NEARBY_VENDORS}?latitude=${lat}&longitude=${long}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const getvendorsProducts = async (categoryId,sort,limit,skip,vendorId) => {
//     if(limit === undefined ){
// limit = ""
//     }
//     if( skip === undefined){
//       skip = ""
//           }
    try {
      const response = await apiPost(`${base_url}${GET_VENDOR_PRODUCTS}?categoryId=${categoryId}&sortBy=${sort}&limit=${limit}&skip=${skip}`, vendorId);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const getCouponDiscount = async (code) => {
    try {
      const response = await apiPost(`${base_url}${COUPON}`, code);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const generateInvoice = async (orderId) => {
    try {
      const response = await apiGet(`${base_url}${GENERATE_INVOICE}/${orderId}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const dataService = {
    getProducts, 
    getProductList,
    getBanners,
    getProductInfo ,  
    getWishlist,
    getCategories,
    addToWishlist,
    getAccount,
    deleteAddress,
    getCart,
    addCart,
    addAddress,
    updateAddress,
    getOrders,
    placeOrder,
    razorpayOrder,
    getNearbyVendors,
    getvendorsProducts,
    getCouponDiscount,
    generateInvoice,
    editProfile,
    uploadProfilePhoto
  }




export default dataService;
