import { version } from "../../../network/api/config";


const MobileWidgets = () => {
  return (
    <div className="offcanvas-widget-area">
      <div className="off-canvas-contact-widget">
        <div className="header-contact-info">
          <ul className="header-contact-info__list">
            <li>
              <i className="fa fa-phone"></i>{" "}
              <a href="tel://8547387485">8547387485</a>
            </li>
            <li>
              <i className="fa fa-envelope"></i>{" "}
              <a href="mailto:abhilash@bloomsmobility.com">admin@bloomsmobility.com</a>
              <h5> Version : {version}</h5>
            </li>
          </ul>
        </div>
      </div>
      
    </div>
  );
};

export default MobileWidgets;
