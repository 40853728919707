import { message } from 'antd';
import axios from 'axios';
import _ from 'lodash';

const getHeaders = () => {
  const token = getTokenFromLocalStorage();
  return {
    Authorization: token,
  };
};

const shownErrors = new Set();

const handleErrorResponse = _.debounce((error) => {
  const status = error.response?.status;
  if (status && !shownErrors.has(status)) {
    switch (status) {
      case 400:
        message.error("Bad Request");
        break;
      case 401:
        message.error("Unauthorized. Please login ");
        break;
      case 403:
        message.error("Session expired. Please logout and login ");
        break;
      case 404:
        message.error("Resource not found");
        break;
      case 408:
        message.error("Request Timeout");
        break;
      case 429:
        message.error("Too Many Requests. Please try again later");
        break;
      case 500:
        message.error("Internal Server Error");
        break;
      case 502:
        message.error("Bad Gateway");
        break;
      case 503:
        message.error("Service Unavailable");
        break;
      case 504:
        message.error("Gateway Timeout");
        break;
      default:
        message.error("An unexpected error occurred");
        break;
    }
    shownErrors.add(status);

    setTimeout(() => {
      shownErrors.delete(status);
    }, 2000);
  }
}, 300, { leading: true, trailing: false });

export const apiGet = async (url, params = {}) => {
  try {
    const response = await axios.get(url, {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
    throw error;
  }
};

export const apiPost = async (url, data = {}) => {
  try {
    const response = await axios.post(url, data, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
    throw error;
  }
};

export const apiPut = async (url, data = {}) => {
  try {
    const response = await axios.put(url, data, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
    throw error;
  }
};

export const apiDelete = async (url) => {
  try {
    const response = await axios.delete(url, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
    throw error;
  }
};

const getTokenFromLocalStorage = () => {
  return localStorage.getItem('jwtTokenUser');
};
