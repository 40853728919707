// import PropTypes from "prop-types";
// import { useEffect, useState } from "react";
// import clsx from "clsx";
// import Logo from "../../components/header/Logo";
// import NavMenu from "../../components/header/NavMenu";
// import IconGroup from "../../components/header/IconGroup";
// import MobileMenu from "../../components/header/MobileMenu";
// import HeaderTop from "../../components/header/HeaderTop";

// const HeaderOne = ({
//   layout,
//   top,
//   borderStyle,
//   headerPaddingClass,
//   headerPositionClass,
//   headerBgClass,
//   updateCartValue,
//   updateWishList
// }) => {
//   const [scroll, setScroll] = useState(0);
//   const [headerTop, setHeaderTop] = useState(0);

//   useEffect(() => {
//     const header = document.querySelector(".sticky-bar");
//     setHeaderTop(header.offsetTop);
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const handleScroll = () => {
//     setScroll(window.scrollY);
//   };

//   return (
//     <header className={clsx("header-area clearfix", headerBgClass, headerPositionClass)}>
//       <div
//         className={clsx(
//           "header-top-area",
//           headerPaddingClass, top === "visible" ? "d-none d-lg-block" : "d-none",
//           borderStyle === "fluid-border" && "border-none"
//         )}
//       >
//         <div className={layout === "container-fluid" ? layout : "container"}>
//           {/* header top */}
//           {/* <HeaderTop borderStyle={borderStyle} /> */}
//         </div>
//       </div>

//       <div
//         className={clsx(
//           headerPaddingClass,
//           "sticky-bar header-res-padding clearfix",
//           scroll > headerTop && "stick"
//         )}
//       >
//         <div className={layout === "container-fluid" ? layout : "container"}>
//           <div className="row">
//             <div className="col-xl-2 col-lg-2 col-md-6 col-4">
//               {/* header logo */}
//               <Logo imageUrl="/assets/img/logo/logo.png" logoClass="logo" />
//             </div>
//             <div className="col-xl-8 col-lg-8 d-none d-lg-block">
//               {/* Nav menu */}
//               <NavMenu />
//             </div>
//             <div className="col-xl-2 col-lg-2 col-md-6 col-8">
//               {/* Icon group */}
//               <IconGroup updateCartValue={updateCartValue} updateWishList={updateWishList} />
//             </div>
//           </div>
//         </div>
//         {/* mobile menu */}
//         <MobileMenu />
//       </div>
//     </header>
//   );
// };

// HeaderOne.propTypes = {
//   borderStyle: PropTypes.string,
//   headerPaddingClass: PropTypes.string,
//   headerPositionClass: PropTypes.string,
//   layout: PropTypes.string,
//   top: PropTypes.string
// };

// export default HeaderOne;

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import clsx from "clsx";
import Logo from "../../components/header/Logo";
import NavMenu from "../../components/header/NavMenu";
import IconGroup from "../../components/header/IconGroup";
import MobileMenu from "../../components/header/MobileMenu";

const HeaderOne = ({
  layout,
  top,
  borderStyle,
  headerPaddingClass,
  headerPositionClass,
  headerBgClass,
  updateCartValue,
  updateCartValueShop,
  updateWishList,
  updateCartSize,
}) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  useEffect(() => {
    const header = document.querySelector(".sticky-bar");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <header
      className={clsx(
        "header-area clearfix",
        headerBgClass,
        headerPositionClass
      )}
    >
      <div
        className={clsx(
          "header-top-area",
          headerPaddingClass,
          top === "visible" ? "d-none d-lg-block" : "d-none",
          borderStyle === "fluid-border" && "border-none"
        )}
      >
        <div className={layout === "container-fluid" ? layout : "container"}>
          {/* header top */}
          {/* <HeaderTop borderStyle={borderStyle} /> */}
        </div>
      </div>

      <div
        className={clsx(
          headerPaddingClass,
          "sticky-bar header-res-padding clearfix",
          scroll > headerTop && "stick"
        )}
      >
        <div className={layout === "container-fluid" ? layout : "container"}>
          <div className="row">
            <div className="col-xl-2 col-lg-2 col-md-6 col-4">
              {/* header logo */}
              {/* <Logo imageUrl={pic} logoClass="side-logo" /> */}
            </div>

            <div className="col-xl-8 col-lg-8 d-none d-lg-block">
              {/* Nav menu */}
              <NavMenu />
            </div>
            <div className="col-xl-2 col-lg-2 col-md-6 col-8">
              {/* Icon group */}
              <IconGroup
                updateCartValue={updateCartValue}
                updateWishList={updateWishList}
                updateCartValueShop={updateCartValueShop}
                updateCartSize={updateCartSize}
              />
            </div>
          </div>
        </div>
        {/* mobile menu */}
        <MobileMenu />
      </div>
    </header>
  );
};

HeaderOne.propTypes = {
  borderStyle: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  layout: PropTypes.string,
  top: PropTypes.string,
};

export default HeaderOne;
