// import PropTypes from "prop-types";
// import clsx from "clsx";
// import ProductgridList from "./ProductgridList";

// const ShopProducts = ({ products, layout }) => {
//   return (
//     <div className="shop-bottom-area mt-35">
//       {products?.length > 0 ? <div className={clsx("row", "grid three-column")}>
//         <ProductgridList products={products} spaceBottomClass="mb-25" />
//       </div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}> <h2>
//         No products available
//       </h2> </div>}
//     </div>
//   );
// };

// ShopProducts.propTypes = {
//   layout: PropTypes.string,
//   products: PropTypes.array
// };

// export default ShopProducts;

import PropTypes from "prop-types";
import clsx from "clsx";
import ProductgridList from "./ProductgridList";

const ShopProducts = ({ products, layout, updateCartFromShop }) => {
  const updateCartShop = () => {
    console.log("update cart");
    updateCartFromShop();
  };
  return (
    <div className="shop-bottom-area mt-35">
      {products?.length > 0 ? (
        <div className={clsx("row", "grid three-column")}>
          <ProductgridList
            updateCartShop={updateCartShop}
            products={products}
            spaceBottomClass="mb-25"
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <h2>No products available</h2>{" "}
        </div>
      )}
    </div>
  );
};

ShopProducts.propTypes = {
  layout: PropTypes.string,
  products: PropTypes.array,
};

export default ShopProducts;
