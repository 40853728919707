import { SET_CURRENCY_SYMBOL } from "../constant/Constant";
const initialState = {
  currencySymbol: "₹",
};

export const CurrencySymbol = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENCY_SYMBOL:
      return {
        ...state,
        currencySymbol: action.payload,
      };
    default:
      return state;
  }
};
