// import PropTypes from "prop-types";
// import React, { Fragment } from "react";
// import { useSelector } from "react-redux";
// import ProductGridSingle from "../../components/product/ProductGridSingle";

// const ProductGridList = ({ products, spaceBottomClass }) => {
//   // const currency = useSelector((state) => state.currency);
//   // const { cartItems } = useSelector((state) => state.cart);
//   // const { compareItems } = useSelector((state) => state.compare);
//   const currency = null;
//   const cartItems = null;
//   const compareItems = null;

//   return (
//     <Fragment>
//       {products?.map((product) => {
//         return (
//           <div className="col-xl-4 col-sm-6" key={product.id}>
//             <ProductGridSingle
//               spaceBottomClass={spaceBottomClass}
//               product={product}
//               currency={currency}
//               cartItem={cartItems?.find(
//                 (cartItem) => cartItem.id === product.id
//               )}
//               // wishlistItem={isWishlisted}
//               // refresh = {getProductList}
//               compareItem={compareItems?.find(
//                 (compareItem) => compareItem.id === product.id
//               )}
//             />
//           </div>
//         );
//       })}
//     </Fragment>
//   );
// };

// ProductGridList.propTypes = {
//   products: PropTypes.array,
//   spaceBottomClass: PropTypes.string,
// };

// export default ProductGridList;

import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import ProductGridSingle from "../../components/product/ProductGridSingle";

const ProductGridList = ({ products, spaceBottomClass, updateCartShop }) => {
  const currency = useSelector((state) => state.currency);
  // const { cartItems } = useSelector((state) => state.cart);
  // const { wishlistItems } = useSelector((state) => state.wishlist);
  // const { compareItems } = useSelector((state) => state.compare);

  const updateCart = () => {
    updateCartShop();
  };

  return (
    <Fragment>
      {products?.map((product) => {
        return (
          <div className="col-xl-5 col-md-6" key={product.id}>
            <ProductGridSingle
              spaceBottomClass={spaceBottomClass}
              product={product}
              currency={currency}
              // cartItem={cartItems.find(
              //   (cartItem) => cartItem.id === product.id
              // )}
              // wishlistItem={isWishlisted}
              // refresh = {getProductList}
              // compareItem={compareItems.find(
              //   (compareItem) => compareItem.id === product.id
              // )}
              // updateCartShop2={updateCartShop2}
              updateCart={updateCart}
            />
          </div>
        );
      })}
    </Fragment>
  );
};

ProductGridList.propTypes = {
  products: PropTypes.array,
  spaceBottomClass: PropTypes.string,
};

export default ProductGridList;
