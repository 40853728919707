import PropTypes from "prop-types";

import { setActiveSort, setShopCategories } from "../../helpers/product";

const ShopCategories = ({ categories, getSortParams ,categoriesList}) => {
  console.log(categoriesList,"categoriesList")
  return (
    <div className="sidebar-widget" style={{ height: "250px", overflowY:"scroll",minWidth:"200px" }}>
      <h4 className="pro-sidebar-title">Categories </h4>
      <div className="sidebar-widget-list mt-30">
        {categoriesList ? (
          <ul>
            <li>
              <div className="sidebar-widget-list-left">
                <button
                  onClick={e => {
                    getSortParams("category", "");
                    setShopCategories(e);
                  }}
                >
                  <span className="checkmark" /> All Categories
                </button>
              </div>
            </li>
            {categoriesList?.map((category, key) => {
              return (
                <li key={key}>
                  <div className="sidebar-widget-list-left">
                  {console.log(category,"categoriesList map")}
                    <button
                      onClick={e => {
                        getSortParams("category", category);
                        setShopCategories(e);
                      }}
                    >
                      {" "}
                      <span className="checkmark" /> {category.name}{" "}
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          "No categories found"
        )}
      </div>
    </div>
  );
};

ShopCategories.propTypes = {
  categories: PropTypes.array,
  getSortParams: PropTypes.func
};

export default ShopCategories;
