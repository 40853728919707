// import { Fragment, useState, useEffect } from "react";
// import Paginator from "react-hooks-paginator";
// import { useSelector } from "react-redux";
// import { useLocation } from "react-router-dom";
// import { getSortedProducts } from "../helpers/product";
// import SEO from "../components/seo";
// import LayoutOne from "../layouts/LayoutOne";
// import Breadcrumb from "../wrappers/breadcrumb/Breadcrumb";
// import ShopSidebar from "../wrappers/product/ShopSidebar";
// import ShopTopbar from "../wrappers/product/ShopTopbar";
// import ShopProducts from "../wrappers/product/ShopProducts";
// import dataService from "../network/api/services/dataService";

// const Categories = () => {
//   const [layout, setLayout] = useState("grid three-column");
//   const [sortType, setSortType] = useState("");
//   const [sortValue, setSortValue] = useState("");
//   const [filterSortType, setFilterSortType] = useState("");
//   const [filterSortValue, setFilterSortValue] = useState("");
//   const [offset, setOffset] = useState(0);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [currentData, setCurrentData] = useState([]);
//   const [sortedProducts, setSortedProducts] = useState([]);
//   // const { products } = useSelector((state) => state.product);
//   const products = null;

//   const [categoriesList, setCategoriesList] = useState([]);
//   const [productList, setproductList] = useState([]);
//   // const [latitude, setLatitude] = useState(null);
//   // const [longitude, setLongitude] = useState(null);
//   const [selectedVendors, setSelectedVendors] = useState([]);
//   const [categoryId, setCategoryId] = useState("");
//   const [limitPage, setlimitPage] = useState(5);
//   const [skipPage, setskipPage] = useState(0);
//   const [totalProducts, settotalProducts] = useState(null);

//   const pageLimit = 10;
//   let { pathname } = useLocation();
//   const [vendors, setVendors] = useState([]);
//   const userId = localStorage.getItem("idUser");

//   const getLayout = (layout) => {
//     setLayout(layout);
//   };

//   const getSortParams = (sortType, sortValue) => {
//     console.log(
//       sortValue,
//       "sortvalue",
//       sortType,
//       "sorttype",
//       selectedVendors,
//       "selectedVendors"
//     );
//     if (sortValue._id) {
//       getProductList(
//         sortValue._id,
//         filterSortValue,
//         limitPage,
//         skipPage,
//         selectedVendors
//       );
//       setCategoryId(sortValue._id);
//     } else {
//       setCategoryId("");

//       const id = "";
//       getProductList(id, filterSortValue, limitPage, skipPage, []);
//     }
//     setSortType(sortType);

//     setSortValue(sortValue);
//   };

//   const getFilterSortParams = (sortType, sortValue) => {
//     setFilterSortType(sortType);
//     setFilterSortValue(sortValue);
//   };

//   // useEffect(() => {
//   //   let sortedProducts = getSortedProducts(products, sortType, sortValue);
//   //   const filterSortedProducts = getSortedProducts(
//   //     sortedProducts,
//   //     filterSortType,
//   //     filterSortValue
//   //   );
//   //   sortedProducts = filterSortedProducts;
//   //   setSortedProducts(sortedProducts);
//   //   setCurrentData(sortedProducts.slice(offset, offset + pageLimit));
//   // }, [offset, products, sortType, sortValue, filterSortType, filterSortValue]);

//   const getCategories = async () => {
//     try {
//       const response = await dataService.getCategories();
//       setCategoriesList(response.categories);
//     } catch (error) {
//       console.error("Error fetching categories:", error);
//     }
//   };

//   useEffect(() => {
//     getCategories();
//   }, []);

//   useEffect(() => {
//     if (filterSortValue) {
//       console.log("categoryId");
//       getProductList(
//         categoryId,
//         filterSortValue,
//         limitPage,
//         skipPage,
//         selectedVendors
//       );
//       console.log(filterSortValue, "filterSortValue inside");
//     }

//     console.log(filterSortValue, "filterSortValue outside");
//   }, [filterSortValue]);

//   const getProductList = async (id, sortBy, limit, skip, vendorId) => {
//     console.log("getProductList");
//     console.log(id, sortBy, vendorId, "parsmas");
//     const payload = {
//       vendorIds: vendorId,
//     };
//     sortBy = filterSortValue ? sortBy : "";
//     try {
//       if (userId) {
//         const response = await dataService.getvendorsProducts(
//           id,
//           sortBy,
//           limit,
//           skip,
//           payload
//         );

//         settotalProducts(response.totalProducts);
//         setproductList(response.data);
//       } else {
//         const response = await dataService.getProducts(
//           id,
//           sortBy,
//           limit,
//           skip,
//           payload
//         );
//         settotalProducts(response.totalProduct);
//         setproductList(response.products);
//       }
//     } catch (error) {
//       console.log(error, "jhgjytd");
//     }
//   };

//   const handleVendorSelect = (vendorId) => {
//     console.log("handleVendorSelect", vendorId);
//     const updatedSelection = selectedVendors.includes(vendorId)
//       ? selectedVendors.filter((id) => id !== vendorId)
//       : [...selectedVendors, vendorId];

//     console.log(selectedVendors, "ppsele");

//     setSelectedVendors(updatedSelection);
//     getProductList(
//       categoryId,
//       sortValue,
//       limitPage,
//       skipPage,
//       updatedSelection
//     );
//   };

//   useEffect(() => {
//     console.log(skipPage, "skipPage useeffect");
//     const id = "";
//     if (!isNaN(currentPage)) {
//       getProductList(id, filterSortValue, limitPage, skipPage, selectedVendors);
//     }
//   }, [skipPage]);

//   useEffect(() => {
//     console.log(
//       currentPage,
//       "currentPage",
//       "limitPage*currentPage",
//       limitPage * currentPage
//     );
//     // setlimitPage(currentPage+5)
//     // setskipPage(skipPage+5)

//     // const newSkipPage = (currentPage - 1) * limitPage;
//     // setskipPage(limitPage*currentPage);
//     console.log(
//       limitPage,
//       "limitPage",
//       currentPage,
//       "currentPage",
//       "newSkipPage"
//     );
//     console.log(isNaN(currentPage), "isNaN(currentPage)");
//     if (isNaN(currentPage)) {
//       setCurrentPage(1);
//       console.log("if", currentPage, "currentPage");
//     } else {
//       console.log("else", currentPage, "currentPage");

//       setCurrentPage(currentPage);
//     }
//     console.log(currentPage, "currentPage");
//     const newSkipPage = (currentPage - 1) * limitPage;
//     console.log(newSkipPage, "newSkipPage currentPage");
//     setskipPage(newSkipPage);

//     // const newLimitPage = currentPage * 5;
//     // setlimitPage(5);
//   }, [currentPage]);

//   const getVendorsByLocation = async () => {
//     try {
//       if ("geolocation" in navigator) {
//         navigator.geolocation.getCurrentPosition(
//           async (position) => {
//             const latitude = position.coords.latitude;
//             const longitude = position.coords.longitude;
//             const response = await dataService.getNearbyVendors(
//               latitude,
//               longitude
//             );
//             setVendors(response.data);
//           },
//           (error) => {
//             console.error("Error getting user's location:", error);
//           }
//         );
//       } else {
//         console.log("Geolocation is not supported by this browser.");
//       }
//     } catch (error) {
//       console.error("Error fetching vendors:", error);
//     }
//   };

//   useEffect(() => {
//     getVendorsByLocation();
//   }, []);

//   console.log(filterSortValue, "filterSortValueuiiiii");

//   return (
//     <Fragment>
//       <SEO titleTemplate="Shop Page" description="Sho." />

//       <LayoutOne headerTop="visible">
//         {/* breadcrumb */}
//         <Breadcrumb
//           pages={[
//             { label: "Home", path: "/" },
//             { label: "Shop", path: pathname },
//           ]}
//         />

//         <div className="shop-area pt-100 pb-100">
//           <div className="container">
//             <div className="row">
//               <div className="col-lg-2 order-2 order-lg-1">
//                 {/* shop sidebar */}
//                 <ShopSidebar
//                   categoriesList={categoriesList}
//                   products={products}
//                   setProducts={setproductList}
//                   getSortParams={getSortParams}
//                   sideSpaceClass="mr-30"
//                   vendors={vendors}
//                   setSelectedVendors={setSelectedVendors}
//                   selectedVendors={selectedVendors}
//                   handleVendorSelect={handleVendorSelect}
//                 />{" "}
//               </div>

//               <div className="col-lg-9 order-1 order-lg-2">
//                 {/* shop topbar default */}
//                 <ShopTopbar
//                   getLayout={getLayout}
//                   getFilterSortParams={getFilterSortParams}
//                   productCount={productList?.length}
//                   sortedProductCount={currentData?.length}
//                 />

//                 {/* shop page content default */}
//                 <ShopProducts layout={layout} products={productList} />

//                 {/* shop product pagination */}
//                 <div className="pro-pagination-style text-center mt-30">
//                   <Paginator
//                     totalRecords={totalProducts}
//                     pageLimit={5}
//                     pageNeighbours={2}
//                     setOffset={setOffset}
//                     currentPage={currentPage}
//                     setCurrentPage={setCurrentPage}
//                     pageContainerClass="mb-0 mt-0"
//                     pagePrevText="«"
//                     pageNextText="»"
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </LayoutOne>
//     </Fragment>
//   );
// };

// export default Categories;

import { Fragment, useState, useEffect } from "react";
import Paginator from "react-hooks-paginator";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// import { getSortedProducts } from "../helpers/product";
import SEO from "../components/seo";
import LayoutOne from "../layouts/LayoutOne";
import Breadcrumb from "../wrappers/breadcrumb/Breadcrumb";
import ShopSidebar from "../wrappers/product/ShopSidebar";
import ShopTopbar from "../wrappers/product/ShopTopbar";
import ShopProducts from "../wrappers/product/ShopProducts";
import dataService from "../network/api/services/dataService";
import { Input, message } from "antd";

const Categories = () => {
  const [layout, setLayout] = useState("grid three-column");
  const [sortType, setSortType] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [filterSortType, setFilterSortType] = useState("");
  const [filterSortValue, setFilterSortValue] = useState("");
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  // const { products } = useSelector((state) => state.product);
  const [categoriesList, setCategoriesList] = useState([]);
  const [productList, setproductList] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [limitPage, setlimitPage] = useState(10);
  const [skipPage, setskipPage] = useState(0);
  const [totalProducts, settotalProducts] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");

  let { pathname } = useLocation();
  const [vendors, setVendors] = useState([]);
  const userId = localStorage.getItem("idUser");

  const getLayout = (layout) => {
    setLayout(layout);
  };

  const handleSearch = (e) => {
    console.log(e.target.value, "searchProd");
    setSearchQuery(e.target.value);
    if (e.target.value) {
      searchProd(e.target.value);
    } else {
      getProductList(
        categoryId,
        filterSortValue,
        limitPage,
        skipPage,
        selectedVendors
      );
    }
  };

  const getSortParams = (sortType, sortValue) => {
    console.log("if");

    console.log(
      sortValue,
      "sortvalue",
      sortType,
      "sorttype",
      selectedVendors,
      "selectedVendors"
    );
    if (sortValue) {
      getProductList(
        sortValue._id,
        filterSortValue,
        limitPage,
        skipPage,
        selectedVendors
      );
      setCategoryId(sortValue._id);
    } else {
      console.log("else");
      setCategoryId("");

      const id = "";
      getProductList(id, filterSortValue, limitPage, skipPage, []);
    }
    setSortType(sortType);

    setSortValue(sortValue);
  };

  const getFilterSortParams = (sortType, sortValue) => {
    setFilterSortType(sortType);
    setFilterSortValue(sortValue);
  };

  const getCategories = async () => {
    try {
      const response = await dataService.getCategories();
      setCategoriesList(response.categories);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const getVendorsByLocation = async () => {
    const id = localStorage.getItem("idUser");

    if (!id) return;
    try {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const response = await dataService.getNearbyVendors(
              latitude,
              longitude
            );
            setVendors(response.data);
          },
          (error) => {
            console.error("Error getting user's location:", error);
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    } catch (error) {
      console.error("Error fetching vendors:", error);
    }
  };

  useEffect(() => {
    getVendorsByLocation();
  }, []);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (filterSortValue) {
      getProductList(
        categoryId,
        filterSortValue,
        limitPage,
        skipPage,
        selectedVendors
      );
      console.log(filterSortValue, "filterSortValue inside");
    }

    console.log(filterSortValue, "filterSortValue outside");
  }, [filterSortValue]);

  const getProductList = async (id, sortBy, limit, skip, vendorId) => {
    console.log(id, sortBy, vendorId, "parsmas");
    const payload = {
      vendorIds: vendorId,
    };
    sortBy = filterSortValue ? sortBy : "";
    try {
      if (userId) {
        const response = await dataService.getvendorsProducts(
          id,
          sortBy,
          limit,
          skip,
          payload
        );

        settotalProducts(response.totalProducts);
        setproductList(response.data);
      } else {
        const response = await dataService.getProducts(
          id,
          sortBy,
          limit,
          skip,
          payload
        );
        settotalProducts(response.totalProduct);
        setproductList(response.products);
      }
    } catch (error) {
      console.log(error, "jhgjytd");
    }
  };

  const handleVendorSelect = (vendorId) => {
    console.log("handleVendorSelect", vendorId);
    const updatedSelection = selectedVendors.includes(vendorId)
      ? selectedVendors.filter((id) => id !== vendorId)
      : [...selectedVendors, vendorId];

    console.log(selectedVendors, "ppsele");

    setSelectedVendors(updatedSelection);
    getProductList(
      categoryId,
      sortValue,
      limitPage,
      skipPage,
      updatedSelection
    );
  };

  useEffect(() => {
    getProductList(
      categoryId,
      filterSortValue,
      limitPage,
      skipPage,
      selectedVendors
    );
  }, [skipPage]);

  useEffect(() => {
    console.log(currentPage, "currentPage", "categoryId", categoryId);
    console.log(
      limitPage,
      "limitPage",
      currentPage,
      "currentPage",
      "newSkipPage"
    );
    if (isNaN(currentPage)) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage);
    }
    console.log(currentPage, "currentPage");
    const newSkipPage = (currentPage - 1) * limitPage;
    console.log(newSkipPage, "newSkipPage currentPage");
    setskipPage(newSkipPage);
  }, [currentPage]);

  const searchProd = async (text) => {
    console.log("searchProd", text);
    const data = {
      name: text,
    };
    try {
      const response = await dataService.searchProducts(data);
      console.log("res", response);
      setproductList(response.data);
    } catch (error) {
      console.error("Error adding to cart:", error);
      message.error("Failed to add product to cart");
    }
  };

  const [isMobileView, setIsMobileView] = useState(false);
  const [updateCartValueShop, setupdateCartValueShop] = useState(true);

  const updateCartFromShop = () => {
    console.log("updateCartFromHome");
    setupdateCartValueShop(!updateCartValueShop);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Fragment>
      <SEO titleTemplate="Shop Page" description="Sho." />

      <LayoutOne updateCartValueShop={updateCartValueShop} headerTop="visible">
        <Breadcrumb
          pages={[
            { label: "Home", path: "/" },
            { label: "Shop", path: pathname },
          ]}
        />

        <div className="shop-area pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <ShopTopbar
                  getLayout={getLayout}
                  getFilterSortParams={getFilterSortParams}
                  productCount={productList?.length}
                  sortedProductCount={currentData?.length}
                />
                <ShopSidebar
                  categoriesList={categoriesList}
                  // products={products}
                  setProducts={setproductList}
                  getSortParams={getSortParams}
                  sideSpaceClass="mr-30"
                  vendors={vendors}
                  setSelectedVendors={setSelectedVendors}
                  selectedVendors={selectedVendors}
                  handleVendorSelect={handleVendorSelect}
                />
              </div>

              <div className="col-lg-9">
                {/* <Input
                  placeholder="Search menu..."
                  value={searchQuery}
                  onChange={handleSearch}
                  style={{ width: "70%", marginBottom: "10px" }}
                /> */}
                <ShopProducts
                  updateCartFromShop={updateCartFromShop}
                  layout={layout}
                  products={productList}
                />

                {/* Pagination Component */}
                <div className="pro-pagination-style text-center mt-5">
                  <Paginator
                    totalRecords={totalProducts}
                    pageLimit={10}
                    // pageNeighbours={2}
                    setOffset={setOffset}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageContainerClass="mb-0 mt-0"
                    pagePrevText="«"
                    pageNextText="»"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default Categories;
