import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MobileNavMenu = () => {
  const { t } = useTranslation();
  const userId = localStorage.getItem("idUser");

  const handleLogout = () => {
    localStorage.removeItem("idUser");
    localStorage.removeItem("jwtTokenUser");
  };
  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li className="menu-item-has-">
          <Link to={"/"}>{t("home")}</Link>
        </li>

        <li>
          <Link to={"/categories"}>{t("shop")}</Link>
        </li>
        <li>
          {userId ? (
            <Link to={"/cart"}>{t("cart")}</Link>
          ) : (
            <Link to={"/login-register"}>{t("cart")}</Link>
          )}
        </li>

        <li>
          {userId ? (
            <Link to={"/wishlist"}>{t("wishlist")}</Link>
          ) : (
            <Link to={"/login-register"}>{t("wishlist")}</Link>
          )}
        </li>
        <li>
          {userId ? (
            <Link to={"/order"}>{t("Orders")}</Link>
          ) : (
            <Link to={"/login-register"}>{t("Orders")}</Link>
          )}
        </li>

        {userId ? (
          <>
            <li>
              <Link to={"/my-account"}>{t("My Account")}</Link>
            </li>
            <li>
              <Link to={"/login-register"} onClick={handleLogout}>
                {t("logout")}
              </Link>
            </li>
          </>
        ) : (
          <li>
            <Link to={"/login-register"}>{t("login / register")}</Link>
          </li>
        )}

        <li>
          <Link to={"/contact"}>{t("Contact us")}</Link>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
