import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Categories from "./pages/Categories";
import { useSelector,  } from "react-redux";
// home pages
const HomeMain = lazy(() => import("./pages/HomeMain"));

// product pages
const Product = lazy(() => import("./pages/Product"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const Orders = lazy(() => import("./pages/other/Order"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const ConfirmedOrder = lazy(() =>
  import("../src/components/confirm-order/ConfirmedOrder")
);

const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = () => {

  const name ="user"

  return (
    <Router basename={`${name}`}>
      <ScrollToTop>
        <Suspense
          fallback={
            <div>
              <div >
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          <Routes>
            <Route path={"/"} element={<HomeMain />} />
            {/* Homepages */}
            <Route path={"/home"} element={<HomeMain />} />
            {/* Shop pages */}
            <Route path={"/categories"} element={<Categories />} />
            {/* Shop product pages */}
            <Route path={"/product/:id"} element={<Product />} />
            {/* Other pages */}
            <Route path={"/about"} element={<About />} />
            <Route path={"/contact"} element={<Contact />} />
            <Route path={"/my-account"} element={<MyAccount />} />
            <Route path={"/login-register"} element={<LoginRegister />} />
            <Route path={"/cart"} element={<Cart />} />
            <Route path={"/order"} element={<Orders />} />
            <Route path={"/wishlist"} element={<Wishlist />} />
            <Route path={"/compare"} element={<Compare />} />
            <Route path={"/checkout"} element={<Checkout />} />
            <Route path={"/confirmedorder"} element={<ConfirmedOrder />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
};

export default App;
