import PropTypes from "prop-types";
import clsx from "clsx";
import {
  getIndividualCategories,
  getIndividualTags,
  getIndividualColors,
  getProductsIndividualSizes,
  getIndividualVendors,
} from "../../helpers/product";

import ShopCategories from "../../components/product/ShopCategories";
import ShopVendors from "../../components/product/ShopVendors";

import { Divider } from "antd";

const ShopSidebar = ({
  products,
  getSortParams,
  sideSpaceClass,
  categoriesList,
  vendors,
  setProducts,
  setSelectedVendors,
  selectedVendors,
  handleVendorSelect,
}) => {
  const uniqueCategories = getIndividualCategories(products);
  const uniqueVendors = getIndividualVendors(products);
  const uniqueColors = getIndividualColors(products);
  const uniqueSizes = getProductsIndividualSizes(products);
  const uniqueTags = getIndividualTags(products);

  const userID = localStorage.getItem("idUser");
  return (
    <div className={clsx("sidebar-style", sideSpaceClass)}>
      {/* shop search */}
      {/* <ShopSearch /> */}

      {/* filter by categories */}
      <ShopCategories
        categoriesList={categoriesList}
        categories={uniqueCategories}
        getSortParams={getSortParams}
      />

      <Divider />

      {userID && (
        <ShopVendors
          selectedVendors={selectedVendors}
          setSelectedVendors={setSelectedVendors}
          setProducts={setProducts}
          vendors={vendors}
          vendorss={uniqueVendors}
          getSortParams={getSortParams}
          handleVendorSelect={handleVendorSelect}
        />
      )}

      {/* filter by color */}
      {/* <ShopColor colors={uniqueColors} getSortParams={getSortParams} /> */}

      {/* filter by size */}
      {/* <ShopSize sizes={uniqueSizes} getSortParams={getSortParams} /> */}

      {/* filter by tag */}
      {/* <ShopTag tags={uniqueTags} getSortParams={getSortParams} /> */}

      {/* filter by Vendors */}
      {/* <ShopVendors vendors={uniqueVendors} getSortParams={getSortParams} /> */}
    </div>
  );
};

ShopSidebar.propTypes = {
  getSortParams: PropTypes.func,
  products: PropTypes.array,
  sideSpaceClass: PropTypes.string,
};

export default ShopSidebar;
